import {useSelector, useDispatch} from 'react-redux';
import {createResourceService, ResourceService} from '../../services/ResourceService';
import { useContext, useEffect, useMemo } from 'react';
import { getResource, getResourceStatus } from '../../selectors/resource-by-id';
import { ResourceStatus, ResourceState } from '../../state/resource';
import { RootState } from '../../state/store';
import { fetchResource } from '../../actions/resource';
import { User } from '../../model/User';
import { Company } from '../../model/Company';
import { ConnectionCounts, Connections, ConnectionRequests, ConnectionSuggestion } from './connections/schema';
import { ResourceByIdState } from '../../state/resource-by-id';

export type KonnectifyState = {
    users: ResourceByIdState<Array<User>>,
    companies: ResourceState<Array<Company>>,
    currentUser: User,
    user: KonnectifyUserState
};

export type KonnectifyUserState = {
    counts: ResourceState<ConnectionCounts>,
    connections: ResourceState<Connections>,
    connectionRequests: ResourceState<ConnectionRequests>,
    eligibleConnections: ResourceState<Array<ConnectionSuggestion>>
};

/*
 * SERVICE HELPERS
 */
export const serviceHeaders = (userId?: string) => userId ? {
    'X-Sedex-As-User': userId
} : undefined;

export type UserResourceService<T> = (userId?: string) => ResourceService<T>

export const createUserResourceService = <T>(baseUrl: string, path: string) => (userId?: string) =>
    createResourceService<T>(baseUrl, path, serviceHeaders(userId));

/*
 * SELECTORS
 */
export const getKonnectifyState = (state: RootState) => state.konnectify;

export const getUserState = (state: RootState) => getKonnectifyState(state).user;

export const getCurrentUser = (state: RootState) => getKonnectifyState(state).currentUser;

/*
 * HOOKS
 */
export const useCurrentUser = () => {
    const user = useSelector(getCurrentUser);
    return user || {}
};

export const useAutoRefreshResource = <S, T> (resourceName: string, resolver: (state: S) => ResourceState<T>, context: React.Context<ResourceService<T>>) => {
    const service = useContext(context);
    const resource = useSelector<S, T>(state => getResource(resolver(state)));
    const status = useSelector<S, ResourceStatus>(state => getResourceStatus(resolver(state)));
    const dispatch = useDispatch();
    useEffect(() => {
        if (status === ResourceStatus.Init || status === ResourceStatus.Stale) {
            dispatch(fetchResource(resourceName, service));
        }
    }, [dispatch, service, resourceName, status]);
    return resource;
};

export const useAutoRefreshUserResource = <S, T> (resourceName: string, resolver: (state: S) => ResourceState<T>, context: React.Context<UserResourceService<T>>) => {
    const factory = useContext(context);
    const user = useCurrentUser();
    const service = useMemo(() => factory(user.id), [factory, user.id]);
    const resource = useSelector<S, T>(state => getResource(resolver(state)));
    const status = useSelector<S, ResourceStatus>(state => getResourceStatus(resolver(state)));
    const dispatch = useDispatch();
    useEffect(() => {
        if (status === ResourceStatus.Init || status === ResourceStatus.Stale) {
            dispatch(fetchResource(resourceName, service));
        }
    }, [dispatch, service, resourceName, status]);
    return resource;
};
