import { FunctionComponent, PropsWithChildren } from "react";
import React from "react";
import { Grid, Paper, Typography, makeStyles, Theme } from "@material-ui/core";
import { useHistory } from "react-router";
import { DashboardRoutes } from "../../router/DashboardRouter";
import DashboardLayout from "../layout/DashboardLayout";

type Props = {
    header: any,
    rightPane: any
}

const MainLayout : FunctionComponent<Props> = (props: PropsWithChildren<Props>) => {

    const classes = useStyles();
    const history = useHistory()

    const resolveTitleFromRoute = (): string => {
        const routeName = history.location.pathname
        const header = DashboardRoutes.find(route => route.path === routeName)
        //currently returning the side bar name here, but we could have a diff property if needed, also may want to show breadcrumbs here
        return header && header.sidebarName ? header.sidebarName : "Default Title";
    }

    return (

        <DashboardLayout>
            <Grid item xs={12} sm={12}>
                <Typography variant='h6'>{resolveTitleFromRoute()}</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Paper className={classes.paper}>
                    {props.header}
                </Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
    
            </Grid> 
            <Grid item xs={12} sm={9}>
                <Paper className={classes.paper}>
                   {props.children}
                </Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Paper className={classes.paper}>
                    {props.rightPane}
                </Paper>
            </Grid>  
            
        </DashboardLayout>
    )
};

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default MainLayout