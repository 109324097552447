import { useAuthStore } from "./AuthProvider";
import { useHistory } from "react-router-dom";
import {isAuthenticated as isAuthenticatedViaStore} from './AuthStore'
import environment from "../../Environment";

const useAuth = () => {

    const auth = useAuthStore();
    const history = useHistory();
    const authUrl = environment.authorisationServiceUrl
    
    const signIn = (userName: string, password: string) => {
        const isAuthenticated = auth.login(userName, password);
        if (isAuthenticated) {
            history.push('/dashboard');
            return true;
        } else {
            return false; // to signal error validation on sign in form
        }
    };

    const navigateIfAuthenticated = () => {
        if (isAuthenticated()) {
            history.push('/dashboard');
            return true;
        } else {
            return false; // to signal error validation on sign in form
        }
    }

    const signOut = () => {
        auth.logout();
        console.log('attempting to sign out')
        const url = `${authUrl}/logout`
        window.location.assign(url)
    };

    const isAuthenticated = () : boolean => {
        return isAuthenticatedViaStore()
    };

    const openAuth0LoginPopup = (state: string) => {
        //const { provider } = this.props
        const width = 400, height = 500
        const left = (window.innerWidth / 2) - (width / 2)
        const top = (window.innerHeight / 2) - (height / 2)

        auth.setStateUuid(state)
       
        const url = `${authUrl}/authenticate?state=${state}`
       
        return window.open(url, '',       
          `toolbar=no, location=no, directories=no, status=no, menubar=no, 
          scrollbars=no, resizable=no, copyhistory=no, width=${width}, 
          height=${height}, top=${top}, left=${left}`
        )
    }

    const authenticateFromCallback = (state: string, token: string) => {
        auth.authenticateFromCallback(state, token)
    }

    return {signIn, signOut, isAuthenticated, openAuth0LoginPopup, authenticateFromCallback, navigateIfAuthenticated}
};

export default useAuth
