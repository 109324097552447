import React from 'react';
import {useConnectionCounts, useInboundConnectionRequests} from "./hooks";
import {
    Button,
    createStyles,
    Grid,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles
} from "@material-ui/core";
import {useCurrentUser} from "../common";
import { ConnectionRequest } from './schema';

const handleAcceptInboundConnectionRequest = () => {
    console.log("STILL TO IMPLEMENT - accepting inbound connection requests")
};
const handleRejectInboundConnectionRequest = () => {
    console.log("STILL TO IMPLEMENT - rejecting inbound connection requests")
};

const InboundConnectionList =  () => {
    const connectionRequests = useInboundConnectionRequests();
    const classes = useStyles();

    if(!connectionRequests) {
        return(<p>Loading....</p>)
    }

    if(!connectionRequests.length) {
        return(<p>No inbound connections requests.</p>)
    }

    return(
        <List>
            <p>Connection requests from other companies</p>
            {connectionRequests.map((connection: ConnectionRequest) => (
                <ListItem key={`${connection.fromCompany.id}-${connection.id}`}>
                    <ListItemText>{connection.fromCompany.name}</ListItemText>
                    <ListItemSecondaryAction>
                        <Button className={classes.inboundRequestButton} variant="outlined" color="primary" onClick={handleAcceptInboundConnectionRequest}>Accept</Button>
                        <Button variant="outlined" color="secondary" onClick={handleRejectInboundConnectionRequest}>Reject</Button>
                    </ListItemSecondaryAction>
                </ListItem>
            ))}
        </List>
    )
};

const InboundConnectionPanel = () => {
    const classes = useStyles();
    const counts = useConnectionCounts();
    const user = useCurrentUser();

    if (!user || !user.id) {
        return (
            <p>Please select a user</p>
        );
    }

    if (!counts) {
        return (
            <p>Loading...</p>
        );
    }

    return(
        <Grid container direction="column">
            <Grid>
                <div className={classes.count}>
                    <span className={classes.countNumber}>{counts.requests.outbound.pending}</span>
                    <span>Pending Outbound Connections</span>
                </div>
            </Grid>
            <Grid>
                <InboundConnectionList/>
            </Grid>
        </Grid>
    )
};

const useStyles = makeStyles(theme => createStyles({
    count: {
        padding: theme.spacing(2),
        background: '#EEE',
        float: 'left'
    },
    countNumber: {
        fontSize: '2em',
        display: 'block'
    },
    inboundRequestButton: {
        margin: theme.spacing(0.5)
    }
}));

export default InboundConnectionPanel
