import React, {FunctionComponent, useEffect} from 'react';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router';

const SignOutCallback: FunctionComponent = () => {

    const history = useHistory();
     
    useEffect(() => {
        
        history.push("/signOut")
        
    },[history])

    return (
        <CircularProgress/>
    )
}

export default SignOutCallback