import { useContext, useMemo } from 'react';
import { useCurrentUser, useAutoRefreshUserResource } from '../common';
import {
    ConnectionCountServiceContext,
    ConnectionServiceContext,
    ConnectionRequestServiceContext,
    postConnectionRequest,
    ConnectionSuggestionServiceContext,
    KONNECTIFY_CONNECTION_SUGGESTIONS_RESOURCE,
    KONNECTIFY_CONNECTION_REQUESTS_RESOURCE,
    KONNECTIFY_CONNECTION_COUNTS_RESOURCE,
    KONNECTIFY_CONNECTIONS_RESOURCE
} from './service';
import {getConnectionCounts, getConnectionRequests, getConnections, getConnectionSuggestions} from './selectors';
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {useDispatch} from "react-redux";
import { RootState } from '../../../state/store';
import { Connection, ConnectionRequest, ConnectionCounts } from './schema';

export const useConnectionCounts = () => useAutoRefreshUserResource<RootState, ConnectionCounts>(
    KONNECTIFY_CONNECTION_COUNTS_RESOURCE,
    getConnectionCounts,
    ConnectionCountServiceContext
);

export const useConnections = (): Array<Connection> => {
    const result = useAutoRefreshUserResource(
        KONNECTIFY_CONNECTIONS_RESOURCE,
        getConnections,
        ConnectionServiceContext
    );
    return result && result.connections;
};

export const useConnectionRequests = () => {
    const result = useAutoRefreshUserResource(
        KONNECTIFY_CONNECTION_REQUESTS_RESOURCE,
        getConnectionRequests,
        ConnectionRequestServiceContext
    );
    return result && result.connectionRequests;
};

export const useInboundConnectionRequests = () => {
    const user = useCurrentUser();
    const connections = useConnectionRequests();
    console.log('FILTERING', connections, user);
    const result = useMemo(
        () => connections && connections.filter((connections: ConnectionRequest) => connections.toCompany.id === user.company.id),
        [connections, user]
    );
    return result;
};

export const useCreateConnectionRequests = () => {
    const user = useCurrentUser();
    const factory = useContext(ConnectionRequestServiceContext);
    const connectionReqService = useMemo(() => factory(user.id), [factory, user.id]);
    const dispatch: ThunkDispatch<RootState, undefined, Action> = useDispatch();
    return (companyId: string) => {
        return dispatch(postConnectionRequest(connectionReqService, {
            toCompanyId: companyId
        }));
    };
};

export const useConnectionSuggestions = () => useAutoRefreshUserResource(
    KONNECTIFY_CONNECTION_SUGGESTIONS_RESOURCE,
    getConnectionSuggestions,
    ConnectionSuggestionServiceContext
);
