import React, { FunctionComponent } from 'react'
import { Route, Switch } from 'react-router-dom';
import { RouteConfig } from './RouteConfig';
import LandingLayout from "../components/layout/LandingLayout";
import LoginCallback from '../components/auth/callback/LoginCallback';
import SignOutCallback from '../components/auth/signout/SignOutCallback';

type Props = {}

export const LandingRoutes: RouteConfig[] = [
    {
        path: '/',
        component: LandingLayout,
    },
    {
        path: '/loginCallback',
        sidebarName: 'Login',
        component: LoginCallback,
    },
    {
        path: '/signOut',
        sidebarName: 'Logout',
        component: LandingLayout,
    },
    {
        path: '/signOutCallback',
        sidebarName: 'Logout',
        component: SignOutCallback,
    },
];

const LandingRouter : FunctionComponent<Props> = (props: Props)  => {
    return (
        <Switch>
            {LandingRoutes.map((route) => (
                <Route
                    exact={route.path === '/'}
                    key={route.path}
                    path={route.path}
                    component={route.component}
                />
            ))}
        </Switch>
    )
};

export default LandingRouter
