import React, {FunctionComponent} from 'react'
import Footer from "../footer/Footer";
import {Container, CssBaseline, makeStyles, Theme, Typography} from "@material-ui/core";
import Header from '../header/Header';
import BackgroundImage from '../../assets/images/sedex-background.jpeg';

const LandingLayout : FunctionComponent = () => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Header />
                <Container component="main" className={classes.main} maxWidth="lg">
                    <Typography className={classes.titleText}>
                        Empowering Responsible Supply Chains
                    </Typography>
                </Container>
            <Footer/>
        </div>
    )
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        flexGrow: 1,
        backgroundImage: 'url('+ BackgroundImage+')',
        backgroundSize: 'cover',
    },
    main: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    titleText: {
        paddingTop: '20vh',
        fontSize: '3em',
        color: 'white'
    }
}));

export default LandingLayout
