import { useContext } from "react";
import { Company } from "../model/Company";
import { fetchResourceById } from "../actions/resource-by-id";
import { getCompanyById } from "../selectors/company";
import { CompanyServiceContext, COMPANY_RESOURCE } from '../services/company';
import { useSelectorWithDispatchIfNotPresent } from './selector';

export const useCompany = (id: string): Company | undefined => {
    const service = useContext(CompanyServiceContext);
    return useSelectorWithDispatchIfNotPresent(
        getCompanyById(id),
        dispatch => dispatch(fetchResourceById(COMPANY_RESOURCE, service, id))
    );
}


