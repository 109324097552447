import React, { FunctionComponent, useState } from "react";
import useAuth from "../useAuth";
import { Button } from "@material-ui/core";
import { v4 as uuidv4 } from 'uuid';

const Auth0Login: FunctionComponent = () => {

    const auth = useAuth()
    let popupWindow: any = null
    const [popupOpen, setPopupOpen] = useState(false)
    
    const checkPopup = ()  => {
        
        const check = setInterval(() => {
    
          if (popupWindow.closed || popupWindow.closed === undefined) {
            setPopupOpen(false)
            console.log('popupWindow closed ', popupWindow.closed, popupOpen)
            clearInterval(check)
            console.log('clear interval ..')
            auth.navigateIfAuthenticated()
          }
        }, 1000)
    }

    const handleAuth0Popup = () => {
        setPopupOpen(true)
        const stateId = uuidv4()
        popupWindow = auth.openAuth0LoginPopup(stateId)
        
        checkPopup() 
    }

    return (
        <Button
            variant="contained"
            onClick={handleAuth0Popup}
            color="secondary"
            id="auth0-sign-in-button"
            data-testid="auth0-sign-in-button"
        >
            SIGN IN
        </Button>

    )
};

export default Auth0Login