import { Dialog, DialogTitle, DialogContent, DialogContentText, Button, DialogActions } from "@material-ui/core";
import { useState, useContext, useMemo, FunctionComponent } from "react";
import React from "react";
import { InvitationServiceContext, postInvitationCode } from "./service";
import { useDispatch } from "react-redux";
import { useCurrentUser } from "../common";

type Props = {
    company: string
}

const CreateCode: FunctionComponent<Props> = (props: Props) => {
    
    const [open, setOpen] = useState<boolean>(false);
    const [invitationCode, setInvitationCode] = useState<string>('');
    const user = useCurrentUser();
    const factory = useContext(InvitationServiceContext);
    const invitationService = useMemo(() => factory(user.id), [factory, user.id]);
    const dispatch = useDispatch();

    const handleClose = () => {
        setOpen(false);
    };

    const handleCopyCode = () => {

    }
    
    const handleGenerateCode = () => {
        console.log("Generating invite from company: " + props.company)
        const body = {
            "invitingCompanyId": props.company
        }

         // @ts-ignore
        dispatch(postInvitationCode(invitationService, body)).then((resp) => updateValue(resp)).catch(() => console.log("Generate invite code failed. ❌"))
    }

    const updateValue = (resp: any) => {
        console.log('got response, resp ', resp)
        const code = resp['invitationCode']
        console.log('got code', code)
        setInvitationCode(code)
        setOpen(true);
    }

    const createCodeDialog = (
        <Dialog
            open={open}
            onClose={handleClose}
        >
             <DialogTitle id="create-code-dialog-title">{"Use this code to invite a company"}</DialogTitle>
             <DialogContent>
                <DialogContentText>
                    Copy the following code:
                </DialogContentText>
                <DialogContentText>
                    {invitationCode}
                </DialogContentText>
            </DialogContent>  
            <DialogActions>
                <Button onClick={handleCopyCode} color="secondary">
                    Copy
                </Button>
                <Button onClick={handleClose} color="primary" autoFocus>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )

    return (
        <div>
            <Button variant="contained" color="secondary" onClick={handleGenerateCode}>
                Create Code
            </Button>
                {createCodeDialog}
        </div>
        
    )
}

export default CreateCode
