import { ResourceByIdService } from '../services/ResourceByIdService';

export const FETCH_RESOURCE_BY_ID = 'resource-by-id/fetch';
export const fetch = (resourceName: string, id: string) => ({
    type: FETCH_RESOURCE_BY_ID, 
    resourceName,
    id
});

export const FETCH_RESOURCE_BY_ID_SUCCESS = 'resource-by-id/fetch/success';
export const fetchSuccess = (resourceName: string, id: string, resource: any) => ({
    type: FETCH_RESOURCE_BY_ID_SUCCESS,
    resourceName,
    id,
    resource
});

export const fetchResourceById = (resourceName: string, service: ResourceByIdService<any>, id: string) => (dispatch: any) => {
    dispatch(fetch(resourceName, id));
    return service.get(id)
        .then((resource: any) => dispatch(fetchSuccess(resourceName, id, resource)));
};
