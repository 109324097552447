import { FunctionComponent } from "react";
import { Hidden, Drawer, makeStyles, Theme, createStyles } from "@material-ui/core";
import React from "react";
import NavBar from "./NavBar";

type Props = {
    handleDrawerToggle: any, 
    mobileDrawerOpen: boolean
}

const MobileNavBar: FunctionComponent<Props> = (props: Props) => {
    const classes = useStyles();
    
    return (
            <Hidden mdUp>
                <div
                   
                    onClick={props.handleDrawerToggle}
                    >
                <Drawer
                    variant="temporary"
                    anchor='left'
                    open={props.mobileDrawerOpen}
                    onClose={props.handleDrawerToggle}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    <NavBar/>
                </Drawer>
                </div>
            </Hidden>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawerPaper: {
            width: 240,
        },
    }),
);

export default MobileNavBar