import {createContext} from 'react';
import environment from '../../../Environment';
import {ResourceService} from '../../../services/ResourceService';
import {postResource} from '../../../actions/resource';
import {createUserResourceService, UserResourceService} from '../common';
import { ConnectionSuggestion, ConnectionCounts, ConnectionRequests, Connections } from './schema';

export const KONNECTIFY_CONNECTION_COUNTS_RESOURCE = 'konnectify/connection-counts';
const connectionCountService = createUserResourceService<ConnectionCounts>(environment.konnectifyServiceUrl, '/connection-counts');
export const ConnectionCountServiceContext = createContext<UserResourceService<ConnectionCounts>>(connectionCountService);

export const KONNECTIFY_CONNECTIONS_RESOURCE = 'konnectify/connections';
const connectionService = createUserResourceService<Connections>(environment.konnectifyServiceUrl, '/connections');
export const ConnectionServiceContext = createContext<UserResourceService<Connections>>(connectionService);

export const KONNECTIFY_CONNECTION_REQUESTS_RESOURCE = 'konnectify/connection-requests';
const connectionRequestService = createUserResourceService<ConnectionRequests>(environment.konnectifyServiceUrl, '/connection-requests');
export const ConnectionRequestServiceContext = createContext<UserResourceService<ConnectionRequests>>(connectionRequestService);

export const KONNECTIFY_CONNECTION_SUGGESTIONS_RESOURCE = 'konnectify/connection-suggestions';
const connectionSuggestionService = createUserResourceService<Array<ConnectionSuggestion>>(environment.konnectifyServiceUrl,'/connection-suggestions');
export const ConnectionSuggestionServiceContext = createContext<UserResourceService<Array<ConnectionSuggestion>>>(connectionSuggestionService);

export const KONNECTIFY_INVITATION_RESOURCE = 'konnectify/invitations';
const invitationService = createUserResourceService<any>(environment.konnectifyServiceUrl, '/invitations');
export const InvitationServiceContext = createContext<UserResourceService<any>>(invitationService);

export const postConnectionRequest = (service: ResourceService<any>, body: any) =>
    postResource(KONNECTIFY_CONNECTION_REQUESTS_RESOURCE, body, service);

export const postInvitationCode = (service: ResourceService<any>, body: any) =>
    postResource(KONNECTIFY_INVITATION_RESOURCE, body, service)

