import React, {FunctionComponent} from 'react'
import {Route, Switch } from 'react-router-dom';
import {RouteConfig} from './RouteConfig';
import OverviewDashboard from '../screens/overview/OverviewDashboard';
import DefaultDashboard from '../screens/DefaultDashboard';
import ProfileDashboard from '../screens/profile/ProfileDashboard';
// import useAuth from '../components/auth/useAuth';
import ConnectionsPoC from "../screens/ConectionsPoC";

type Props = {}

export const HomeRoutes: RouteConfig[] = [
    {
        path: '/dashboard',
        sidebarName: 'Overview Dashboard',
        component: OverviewDashboard,
    },
];

export const ConnectionRoutes: RouteConfig[] = [
    {
        path: '/connections/poc',
        sidebarName: 'PoC',
        component: ConnectionsPoC,
    },
    {
        path: '/connections/suppliers',
        sidebarName: 'Suppliers',
        component: DefaultDashboard,
    },
    {
        path: '/connections/customers',
        sidebarName: 'Customers',
        component: DefaultDashboard,
    },
    {
        path: '/connections/auditors',
        sidebarName: 'Auditors',
        component: DefaultDashboard,
    },
];

export const ServiceRoutes: RouteConfig[] = [
    {
        path: '/services/routesOfSupply',
        sidebarName: 'Routes of Supply',
        component: DefaultDashboard,
    },
    {
        path: '/services/buyerSourcing',
        sidebarName: 'Buyer Sourcing',
        component: DefaultDashboard,
    },
    {
        path: '/services/assessmentAndAudit',
        sidebarName: 'Assessment and Audit',
        component: DefaultDashboard,
    },
    {
        path: '/services/analytics',
        sidebarName: 'Analytics',
        component: DefaultDashboard,
    },
];

export const MenuRoutes: RouteConfig[] = [
    {
        path: '/profile',
        sidebarName: 'Profile',
        component: ProfileDashboard,
    }
];

export const DashboardRoutes = HomeRoutes.concat(ConnectionRoutes).concat(ServiceRoutes).concat(MenuRoutes)

const PrivateRoute = (route: RouteConfig) => {
    // const auth = useAuth()

    // const finalComponent = auth.isAuthenticated() === true ? route.component : LandingLayout;
    const finalComponent = route.component

    return <Route
        exact={route.path === '/'}
        key={route.path}
        path={route.path}
        component={finalComponent} 
     />;
};

const DashboardRouter : FunctionComponent<Props> = (props: Props)  => {
   
    return (
        <Switch>
            {DashboardRoutes.map((route) => (

                PrivateRoute(route)
        
            ))}
        </Switch>
    )
};


export default DashboardRouter
