import React from 'react';

import LandingRouter from './router/LandingRouter';
import {BrowserRouter} from 'react-router-dom';
import DashboardRouter from './router/DashboardRouter';
import { Provider } from 'react-redux';
import createStore from './state/store';

const App = () => {
    return (
        <Provider store={createStore()}>
            <BrowserRouter>
                <LandingRouter/>
                <DashboardRouter/>
            </BrowserRouter>
        </Provider>
    );
};

export default App

