import React, {FunctionComponent, PropsWithChildren} from 'react'
import Footer from "../footer/Footer";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {
    Container,
    CssBaseline,
    Grid
} from "@material-ui/core";
import Header from '../header/Header';
import NavBar from '../navigation/NavBar';

type Props = {}

const DashboardLayout : FunctionComponent = (props: PropsWithChildren<Props>) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>

            <CssBaseline />
          
            <Header />
            <Container component="main" className={classes.main} maxWidth="lg">

                <Grid container
                      justify="flex-start"
                      direction="row"
                      alignItems="flex-start"
                      spacing={1}
                      >
                    <Grid item className={classes.menu}>
                        <NavBar/>
                    </Grid>
                
                    <Grid container 
                    direction="row"
                    spacing={1} 
                    item 
                    xs 
                    alignItems="flex-start" 
                    justify="flex-start">
                        {props.children}
                    </Grid>
                </Grid>
        

            </Container>
            <Footer />
           
        </div>
    )
};

const drawerWidth = 180;

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        flexGrow: 1,
    },
    main: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    menu: {
        display: 'none',
        width: drawerWidth,
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    
}));

export default DashboardLayout
