import AxiosTransportLayer, { TransportLayer } from './TransportLayer';

export type ResourceService<T> = {
    get(): Promise<T>
    post(body: any): Promise<T>
};

class PathBasedResourceService<T> implements ResourceService<T> {
    private transportLayer: TransportLayer<T>;
    private resourcePath: string;

    constructor(transportLayer: TransportLayer<T>, resourcePath: string) {
        this.transportLayer = transportLayer;
        this.resourcePath = resourcePath;
    }

    get(): Promise<T> {
        return this.transportLayer.get(this.resourcePath);
    }

    post(body: any): Promise<T> {
        return this.transportLayer.post(this.resourcePath, body);
    }
}

export const createResourceService = <T>(baseUrl: string, resourcePath: string, headers?: any) => {
    const transportLayer = new AxiosTransportLayer<T>(baseUrl, headers);
    const resourceService = new PathBasedResourceService<T>(transportLayer, resourcePath);
    return resourceService;
}

export default PathBasedResourceService;
