import React from 'react';
import { makeStyles, createStyles, Paper, Theme, Typography } from '@material-ui/core';

type PanelProps = {
    title: string;
    children?: any;
};

const Panel = ({ title, children }: PanelProps) => {
    const classes = useStyles();
    return (
        <Paper classes={{ root: classes.root }} elevation={0}>
            <Typography variant="h6" data-testid="panel-title" gutterBottom>{title}</Typography>
            {children}
        </Paper>
    );
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        textAlign: 'left',
        padding: theme.spacing(2)
    }
}));

export default Panel;
