import { combineReducers } from 'redux';
import { FETCH_RESOURCE_BY_ID_SUCCESS } from '../actions/resource-by-id';

interface Action {
    type: string
};

interface ResourceByIdAction<T> extends Action {
    id: string,
    resource: T,
    resourceName: string
};

export interface ResourceMap<T> {
    [id: string]: T
};

export type ResourceByIdState<T> = {
    byId: ResourceMap<T>
};

const initialByIdState = {};
const byIdReducer = <T> (resourceName: string) => (state = initialByIdState, action: Action) => {
    switch (action.type) {
        case FETCH_RESOURCE_BY_ID_SUCCESS:
            return resourceName === (action as ResourceByIdAction<T>).resourceName ?
                { ...state, [(action as ResourceByIdAction<T>).id]: (action as ResourceByIdAction<T>).resource } :
                state;
        default:
            return state;
    }
}

export default (resourceName: string) => combineReducers({
    byId: byIdReducer(resourceName)
});
