import {ResourceService} from '../services/ResourceService';
import {ThunkAction} from "redux-thunk";

export const FETCH_RESOURCE = 'resource/fetch';
const fetch = (resourceName: string) => ({
    type: FETCH_RESOURCE,
    resourceName
});

export const FETCH_RESOURCE_SUCCESS = 'resource/fetch/success';
const fetchSuccess = (resourceName: string, resource: any) => ({
    type: FETCH_RESOURCE_SUCCESS,
    resourceName,
    resource
});

export const fetchResource = (resourceName: string, service: ResourceService<any>) => (dispatch: any) => {
    dispatch(fetch(resourceName));
    return service.get()
        .then(result => dispatch(fetchSuccess(resourceName, result)));
};


export const POST_RESOURCE = 'resource/post'
const post = (resourceName: string, body: any) => ({
    type: POST_RESOURCE,
    resourceName,
    body
})

export const POST_RESOURCE_SUCCESS = 'resource/post/success'
const postSuccess =  (resourceName: string, resource: any) => ({
    type: POST_RESOURCE_SUCCESS,
    resourceName,
    resource
});

export const isPostSuccess = (resourceName: string) => (action: any) =>
    action.type === POST_RESOURCE_SUCCESS && action.resourceName === resourceName;

export const postResource = (resourceName: string, body: any, service: ResourceService<any>): ThunkAction<Promise<any>, any, any, any> => (dispatch: any) => {
    dispatch(post(resourceName, body));
    return service.post(body)
        .then(result => {
            dispatch(postSuccess(resourceName, result))
            return result
        })
}
