import React, { useCallback, FunctionComponent } from 'react';
import {useCurrentUser} from '../common';
import {
    useConnectionCounts,
    useConnections,
    useCreateConnectionRequests,
    useConnectionSuggestions
} from './hooks';
import {createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, List, ListItem, makeStyles, ListItemSecondaryAction, ListItemText} from '@material-ui/core';
import Button from "@material-ui/core/Button";
import CreateCode from "./CreateCode";
import { ConnectionSuggestion as ConnectionSuggestionProps, Connection } from './schema';

const ConnectionList: FunctionComponent = () => {
    const connections = useConnections();

    if (!connections) {
        return (
            <p>Loading...</p>
        );
    }

    if (!connections.length) {
        return (
            <p>You don't currently have any connections</p>
        );
    }

    return (
        <List>
            {connections.map((connection: Connection) => (
                <ListItem key={`${connection.company.id}-${connection.id}`}>
                    <p>{connection.connectedCompany.name}</p>
                </ListItem>
            ))}
        </List>
    )
};

const ConnectionSuggestion: FunctionComponent<{ company: ConnectionSuggestionProps, setError: (message: string) => void }> = ({ company, setError }) => {
    const createConnectionRequest = useCreateConnectionRequests();
    const handleInvite = useCallback(() => {
        return createConnectionRequest(company.id)
            .catch(() => setError("Error - Connection invite failed. ❌"))
    }, [createConnectionRequest, setError, company.id]);
    return (
        <ListItem>
            <ListItemText>{company.name}</ListItemText>
            <ListItemSecondaryAction><Button variant="contained" color="primary" onClick={handleInvite}>Connect</Button></ListItemSecondaryAction>
        </ListItem>
    );
};

const ConnectionCount: FunctionComponent = () => {
    const classes = useStyles();
    const counts = useConnectionCounts();
    return counts && (
        <div className={classes.count}>
            <span className={classes.countNumber}>{counts.connections}</span>
            <span>Connections</span>
        </div>
    );
};

const ConnectDialog: FunctionComponent<{ open: boolean, handleClose: () => void }> = ({ open, handleClose }) => {

    const user = useCurrentUser();
    const classes = useStyles();
    const [error, setError] = React.useState("");
    const connectionSuggestions = useConnectionSuggestions();

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Invite company to connect with"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    How about connecting with one of these companies.
                </DialogContentText>
                <List>
                    {connectionSuggestions && connectionSuggestions.map((suggestion: ConnectionSuggestionProps) => (
                        <ConnectionSuggestion key={suggestion.id} company={suggestion} setError={setError}/>
                    ))}
                </List>
                <DialogContentText id="alert-dialog-description">
                   ...or create a code to send.
                </DialogContentText>
                <DialogContentText className={classes.errorAlert}>
                    {error}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <CreateCode company={user.company.id}/>
                <Button onClick={handleClose} variant="contained" color="primary">
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const ConnectionsPanel: FunctionComponent = () => {
    const classes = useStyles();
    const user = useCurrentUser();
    
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    if (!user.id) {
        return (
            <p>Please select a user</p>
        );
    }

    return (
        <Grid container direction="column">
            <Grid>
                <ConnectionCount/>
                <div className={classes.invite}>
                    <Button variant="contained" color="primary" onClick={handleClickOpen}>Invite</Button>
                    <ConnectDialog open={open} handleClose={handleClose}/>
                </div>
            </Grid>
            <Grid>
                <ConnectionList/>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(theme => createStyles({
    count: {
        padding: theme.spacing(2),
        background: '#EEE',
        float: 'left'
    },
    countNumber: {
        fontSize: '2em',
        display: 'block'
    },
    invite: {
        padding: theme.spacing(2),
        float: 'right'
    },
    errorAlert: {
        color: 'red',
        textAlign: 'center'
    }

}))

export default ConnectionsPanel;
