import React from 'react';
import { Address as AddressProps } from '../../../model/Address';

const Address = ({ line1, line2, city, country: { name: country }, postalCode }: AddressProps) => (
    <div>
        <span data-testid="address-line1">{line1}</span><br/>
        {line2 && (
            <>
                <span data-testid="address-line2">{line2}</span><br/>
            </>
        )}
        <span data-testid="address-city">{city}</span><br/>
        <span data-testid="address-country">{country}</span><br/>
        <span data-testid="address-postalcode">{postalCode}</span>
    </div>
);

export default Address
