import React, {FunctionComponent} from "react"
import MainLayout from "../../components/dashboardcontent/MainLayout"
import HomeScreen from "../HomeScreen"
import {Typography} from "@material-ui/core"

const OverviewDashboard: FunctionComponent = () => {

    return (
        <MainLayout
            header={<Typography>Overview Header</Typography>}
            rightPane={<Typography>Overview right content...</Typography>}
        >
            <HomeScreen/>
        </MainLayout>
    )
};

export default OverviewDashboard
