import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

export const useSelectorWithDispatchIfNotPresent = (selector: (state: any) => any, dispatcher: (dispatch: any) => any): any => {
    const result = useSelector(selector);
    const dispatch = useDispatch();
    useEffect(() => {
        if (result == null) {
            dispatcher(dispatch);
        }
    }, [dispatcher, dispatch, result]);
    return result;
};

export const useSelectorWithDispatchOnCondition = (selector: (state: any) => any, condition: (result: any) => boolean, dispatcher: (dispatch: any) => any): any => {
    const result = useSelector(selector);
    const dispatch = useDispatch();
    useEffect(() => {
        if (condition(result)) {
            dispatcher(dispatch);
        }
    }, [condition, dispatcher, dispatch, result]);
    return result;
};
