import React, {FunctionComponent} from 'react'
import {Typography} from "@material-ui/core";

const HomeScreen : FunctionComponent = () => {
    return(
        <>
            <Typography id="overview-content">Overview content</Typography>
        </>
    );
};

export default HomeScreen;
