import React, {FunctionComponent} from 'react';
import {Button, createStyles, Grid, Menu, MenuItem} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Theme} from "@material-ui/core/styles/createMuiTheme";
import {Skeleton} from "@material-ui/lab";
import { Link } from "react-router-dom";
import useAuth from '../useAuth';
import { AuthProps } from './AuthMenu';

const SkeletonFakeContent : FunctionComponent = () => {
    return(
        <Grid item>
            <Skeleton animation={false} height="2.5em" width="10em"/>
            <Skeleton animation={false} height="2.5em" width="8em"/>
            <Skeleton animation={false} height="2.5em" width="15em"/>
        </Grid>
    )
};

const SignOut : FunctionComponent<AuthProps> = (props: AuthProps) => {
    const classes = useStyles();
    const isMenuOpen = Boolean(props.anchorEl);
    const auth = useAuth()

    const handleSignOut = () => {
        auth.signOut()
        props.handleClose()
    }

    return(
        <Grid container>
            <Menu
                id="simple-menu"
                anchorEl={props.anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                open={isMenuOpen}
                onClose={props.handleClose}
                className={classes.menu}
                PaperProps={{
                    style: {
                        width: '60ch',
                        height: '56ch',
                        padding: '2ch'
                    }
                }}
            >
                <Grid container>
                    <Link to='/profile' style={{ textDecoration: 'none' }}>
                        <MenuItem data-testid="log-in-menu-profile" onClick={props.handleClose} className={classes.menuText}>My Profile</MenuItem>
                    </Link>
                    <Grid container direction="row" item xs={12}>
                        <Grid item className={classes.menuMargin}>
                            <Skeleton variant="circle" width={90} height={90} />
                        </Grid>
                        <Grid item>
                            <SkeletonFakeContent />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <hr/>
                        <MenuItem onClick={props.handleClose} className={classes.menuText}>My account</MenuItem>
                        <SkeletonFakeContent />
                    </Grid>
                    <Grid item xs={12}>
                        <hr/>
                        <Button
                            id="sign-out-button"
                            data-testid="sign-out-button"
                            variant="contained"
                            color="secondary"
                            disableElevation
                            onClick={handleSignOut}
                            style={{
                                marginTop: '10px'
                            }}
                        >
                            Sign out
                        </Button>
                    </Grid>
                </Grid>
            </Menu>
        </Grid>
    )
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menu: {
            width: '100%'
        },
        menuMargin: {
            margin: '10px'
        },
        menuText: {
            color: 'black'
        }
    }),
);

export default SignOut
