
export const isAuthenticated = (): boolean => {
    const getFromStorage = localStorage.getItem("authenticated")
    return getFromStorage && getFromStorage === "true" ? true : false 
}

export default class AuthStore {

    isAuthenticated() {
        return isAuthenticated()
    }

    login = (userName: string, password: string): boolean => {
        if (userName === "admin" && password === "n1njasr0ck") {
            console.log('all good letting you in ', userName)
            localStorage.setItem("authenticated", "true")
        } else {
            console.log('login failed for ', userName)
            localStorage.setItem("authenticated", "false")
        }
        
        return isAuthenticated() 
    }

    logout = () => {
        console.log('logging out ..')
        localStorage.removeItem("authenticated")
        localStorage.removeItem("token")
        localStorage.removeItem("state")
    }

    setStateUuid = (stateUuid: string) => {
        localStorage.setItem("state", stateUuid)
    }

    authenticateFromCallback = (state: string, token: string) => {
        console.log('authenticated from callback ..', state)
        const currentStateId = localStorage.getItem("state") as string
        console.log('current state from storage ', currentStateId)
        
        if (currentStateId === state) {
            console.log('boom, let us in ')
            localStorage.setItem("authenticated", "true")
            localStorage.setItem("token", token)
        } else {
            localStorage.setItem("authenticated", "false")
            localStorage.setItem("token", token)
        }
    }

}