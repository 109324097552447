import AxiosTransportLayer, { TransportLayer } from './TransportLayer';

export type ResourceByIdService<T> = {
    get(id: string): Promise<T>
};

class PathBasedResourceByIdService<T> implements ResourceByIdService<T> {

    private transportLayer: TransportLayer<T>;
    private resourcePathFn: (id: string) => string;

    constructor(transportLayer: TransportLayer<T>, resourcePathFn: (id: string) => string) {
        this.transportLayer = transportLayer;
        this.resourcePathFn = resourcePathFn;
    }

    get(id: string) {
        return this.transportLayer.get(this.resourcePathFn(id));
    }
}

export const createResourceByIdService = <T>(baseUrl: string, resourcePathFn: (id: string) => string) => {
    const transportLayer = new AxiosTransportLayer<T>(baseUrl);
    const companyService = new PathBasedResourceByIdService(transportLayer, resourcePathFn);
    return companyService;
};

export default PathBasedResourceByIdService;
