import React, {FunctionComponent} from "react";
import MainLayout from "../../components/dashboardcontent/MainLayout";
import BillingInfo from "../../components/profile/billingInfo/BillingInfo";
import CompanyInfo from '../../components/profile/CompanyInfo';
import {HardCodedUserInfo} from "../../components/profile/userInfo/UserInfo";

const ProfileDashboard: FunctionComponent = () => {

    return (
        <MainLayout
            header={<HardCodedUserInfo/>}
            rightPane={<BillingInfo />}
        >
            <CompanyInfo/>
        </MainLayout>
    )
};

export default ProfileDashboard
