import axios from 'axios';

export type TransportLayer<T> = {
    get: (resource: string) => Promise<T>
    post: (resource: string, body: any) => Promise<T>
}

type Response<T> = {
    data: T
};

class AxiosTransportLayer<T> implements TransportLayer<T> {
    private baseUrl: string;
    private headers?: any;

    constructor(baseUrl: string, headers?: any) {
        this.baseUrl = baseUrl;
        this.headers = headers;
    }

    get(resource: string): Promise<T> {
        return axios.get(this.baseUrl + resource, { headers: this.headers })
            .then((response: Response<T>) => response.data);
    }

    post(resource: string, body: any): Promise<T> {
        return axios.post(this.baseUrl + resource, body, {headers: this.headers})
            .then((response: Response<T>) => response.data);
    }
}

export default AxiosTransportLayer;
