import React, {FunctionComponent} from "react"
import UserSwitch from "../components/konnectify/user/UserSwitch";
import ConnectionsPanel from '../components/konnectify/connections/ConnectionsPanel';
import InboundConnectionPanel from "../components/konnectify/connections/InboundConnectionPanel";
import {Grid, makeStyles, Paper, Theme, Typography} from "@material-ui/core";
import DashboardLayout from "../components/layout/DashboardLayout";

const ConnectionsPoC: FunctionComponent = () => {
    const classes = useStyles();
    return (
        <DashboardLayout>
            <Grid item xs={12} sm={12}>
                <Typography variant='h6'>Connections PoC</Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                    <UserSwitch/>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Paper className={classes.paper}>
                    <ConnectionsPanel/>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Paper className={classes.paper}>
                    <InboundConnectionPanel/>
                </Paper>
            </Grid>
        </DashboardLayout>
    )
};

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default ConnectionsPoC
