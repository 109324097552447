import React, { FunctionComponent } from 'react';
import {createStyles, fade, makeStyles, Theme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {Container } from '@material-ui/core';
import AuthMenu from '../auth/menu';
import MobileNavBar from '../navigation/MobileNavBar';
import useAuth from '../auth/useAuth'
import logo from '../../assets/images/sedex_logo_gray.png';
import Auth0Login from '../auth/menu/Auth0Login';

const Header: FunctionComponent = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);
    const auth = useAuth();

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDrawerToggle = () => {
        setMobileDrawerOpen(!mobileDrawerOpen)
    };

    const menuId = 'primary-search-account-menu';

    return (
        <div className={classes.header}>
        <Container maxWidth="lg">
            <AppBar position="static" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    {auth.isAuthenticated() === true &&
                        <div className={classes.burgerMenuMobile}>
                            <IconButton
                                edge="start"
                                className={classes.menuButton}
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerToggle}
                            >
                                <MenuIcon />
                            </IconButton>
                        </div>
                    }
                    <img alt="Sedex Logo" src={logo} height={25} />
                    <Typography className={classes.title} variant="h6" noWrap>
                        Connect Hub
                    </Typography>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </div>
                    <div className={classes.grow} />
                    <div>
                        {auth.isAuthenticated() === true ? (
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                            id="user-profile-menu"
                            data-testid="user-profile-menu"
                        >    
                            <AccountCircle />
                        </IconButton>
                        ) : (
                            <Auth0Login/>
                        )}
                    </div>
                </Toolbar>
            </AppBar>
            <MobileNavBar handleDrawerToggle={handleDrawerToggle} mobileDrawerOpen={mobileDrawerOpen} />
            <AuthMenu handleClick={handleProfileMenuOpen} handleClose={handleMenuClose} anchorEl={anchorEl}/>
        </Container>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
        },
        header: {
            backgroundColor: '#d7d7d7'
        },
        toolbar: {
            paddingLeft: '0px',
            paddingRight: '0px',
        },
        appBar: {
            boxShadow: '0px 0px 0px 0px',
            backgroundColor: '#d7d7d7',
            color: 'black'
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(3),
                width: 'auto',
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '20ch',
            },
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        burgerMenuMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        drawerPaper: {
            width: 240,
        },
    }),
);

export default Header
