import React, {FunctionComponent} from 'react';
import SignOut from './SignOut';
import SignIn from './SignIn';
import useAuth from '../useAuth';

export type AuthProps = {
    handleClick: any
    handleClose: any
    anchorEl: null | HTMLElement
}

export const AuthMenu: FunctionComponent<AuthProps> = (props: AuthProps) => {

    const auth = useAuth()

    return (
        auth.isAuthenticated() === true ? 
        (<SignOut {...props} />): 
        (<SignIn {...props} />)
    )
};
