import React from 'react';
import {FormControl, Grid, InputLabel, MenuItem, Select} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useCompanies} from "../CompaniesPoC";
import {Company} from "../../../model/Company";
import {User} from "../../../model/User";
import {useUsersForCompany} from "../UsersPoC";
import {useDispatch} from 'react-redux';
import {changeUser} from '../../../state/user';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        formControl: {
            width: '100%'
        },
        button: {
            width: '100%',
            height: '5ch',
            marginTop: '2ch'
        },
    }),
);

const UserSwitch = () => {
    const classes = useStyles();

    const [company, setCompany] = React.useState('');
    const [user, setUser] = React.useState('');

    const companyList : Array<Company> = useCompanies()
    const userList : Array<User> = useUsersForCompany(company || undefined)

    const dispatch = useDispatch();

    const handleCompanyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setCompany(event.target.value as string);
    };

    const handleUserChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const userId = event.target.value as string;
        setUser(userId);
        const user = userList.find((user: User) => user.id === userId);
        dispatch(changeUser(user));
    };

    return (
        <div>
            Select the user to simulate here....
            <form className={classes.formControl}>
                <Grid container spacing={1}>
                    <Grid component={FormControl} item xs={12} sm={12} md={6} lg={6}>
                        <InputLabel id="select-company-label">Company</InputLabel>
                        <Select
                            labelId="select-company-label"
                            id="select-company"
                            value={company}
                            fullWidth
                            onChange={handleCompanyChange}
                        >
                            {companyList && companyList.map((company: Company) => (
                                <MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid component={FormControl} item xs={12} sm={12} md={6} lg={6}>
                        <InputLabel id="select-user-label">User</InputLabel>
                        <Select
                            labelId="select-user-label"
                            id="select-user"
                            value={user}
                            fullWidth
                            onChange={handleUserChange}
                        >
                            {userList && userList.map((user: User) => (
                                <MenuItem key={user.id} value={user.id}>{`${user.firstName} ${user.lastName}`}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};

export default UserSwitch
