import {applyMiddleware, combineReducers, createStore} from 'redux';
import {createLogger} from 'redux-logger';
import thunk from 'redux-thunk';
import resourceByIdReducer from './resource-by-id';
import resourceReducer from './resource';
import {COMPANY_RESOURCE} from '../services/company';
import {BILLING_INFO_RESOURCE} from '../services/profile';
import {KONNECTIFY_COMPANIES_RESOURCE} from "../components/konnectify/CompaniesPoC";
import {KONNECTIFY_USERS_RESOURCE} from "../components/konnectify/UsersPoC";
import {
    KONNECTIFY_CONNECTION_COUNTS_RESOURCE,
    KONNECTIFY_CONNECTIONS_RESOURCE,
    KONNECTIFY_CONNECTION_SUGGESTIONS_RESOURCE,
    KONNECTIFY_CONNECTION_REQUESTS_RESOURCE
} from '../components/konnectify/connections/service';
import currentUserReducer, {userState} from './user';
import { isPostSuccess } from '../actions/resource';
import { KonnectifyState } from '../components/konnectify/common';

export type RootState = {
    profile: any,
    company: any,
    konnectify: KonnectifyState
};

const matchAny = <T> (...matchers: Array<(thing: T) => boolean>) => (thing: T): boolean =>
    matchers.some(matcher => matcher(thing));

const rootReducer = combineReducers({
    profile: combineReducers({
        billingInfo: resourceReducer(BILLING_INFO_RESOURCE)
    }),
    company: resourceByIdReducer(COMPANY_RESOURCE),
    konnectify: combineReducers({
        users: resourceByIdReducer(KONNECTIFY_USERS_RESOURCE),
        companies: resourceReducer(KONNECTIFY_COMPANIES_RESOURCE),
        currentUser: currentUserReducer,
        user: userState(combineReducers({
            counts: resourceReducer(KONNECTIFY_CONNECTION_COUNTS_RESOURCE, {
                invalidatedWhen: matchAny(
                    isPostSuccess(KONNECTIFY_CONNECTION_REQUESTS_RESOURCE),
                    //isPostSuccess(KONNECTIFY_CONNECTION_REQUEST_ACCEPT_RESOURCE)
                    //isPostSuccess(KONNECTIFY_CONNECTION_REQUEST_REJECT_RESOURCE)
                )
            }),
            connections: resourceReducer(KONNECTIFY_CONNECTIONS_RESOURCE),
            connectionRequests: resourceReducer(KONNECTIFY_CONNECTION_REQUESTS_RESOURCE, {
                invalidatedWhen: isPostSuccess(KONNECTIFY_CONNECTION_REQUESTS_RESOURCE)
            }),
            eligibleConnections: resourceReducer(KONNECTIFY_CONNECTION_SUGGESTIONS_RESOURCE, {
                invalidatedWhen: isPostSuccess(KONNECTIFY_CONNECTION_REQUESTS_RESOURCE)
            })
        }))
    })
});

const logger = createLogger();

export default (logging = true) => createStore(
    rootReducer,
    applyMiddleware(thunk, ...(logging ? [logger] : []))
);
