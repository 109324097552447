export class EnvConfig {

    konnectifyServiceUrl: any
    companyServiceUrl: any
    billingServiceUrl: any
    authorisationServiceUrl: any
   
    constructor() {
        this.konnectifyServiceUrl = process.env.REACT_APP_KONNECTIFY_SERVICE_BASE_URL;
        this.companyServiceUrl = process.env.REACT_APP_COMPANY_SERVICE_BASE_URL;
        this.billingServiceUrl = process.env.REACT_APP_BILLING_INFO_SERVICE_BASE_URL;
        this.authorisationServiceUrl = process.env.REACT_APP_AUTHORISATION_SERVICE_URL;
        
        console.log('configured connectionServiceUrl url ', this.konnectifyServiceUrl)
        console.log('configured companyServiceUrl url ', this.companyServiceUrl)
        console.log('configured billingServiceUrl uri ', this.billingServiceUrl)
        console.log('configured authorisationServiceUrl uri ', this.authorisationServiceUrl)
        
        if (this.konnectifyServiceUrl && this.companyServiceUrl && this.billingServiceUrl && this.authorisationServiceUrl) {
            
        } else {
            throw new Error('Unable to start application, check env variables REACT_APP_KONNECTIFY_SERVICE_BASE_URL, REACT_APP_COMPANY_SERVICE_BASE_URL, REACT_APP_BILLING_INFO_SERVICE_BASE_URL and REACT_APP_AUTHORISATION_SERVICE_URL are set' )
        }
    }
}

const environment: EnvConfig = new EnvConfig()

export default environment;
