import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import {makeStyles} from '@material-ui/core/styles';

type SkeletonProps = {
    minNumberOfElements: number
    maxNumberOfElements: number
    minWidthPercentage: number
    maxWidthPercentage: number
}

const useStyles = makeStyles({
    root: {
        width: '100%',
    }
});

function randomIntFromInterval(min: number, max: number): number { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
}

function CreateSkeleton(props: SkeletonProps) : any {
    let skeleton: any[] = [];
    for(let i = 0; i < randomIntFromInterval(props.minNumberOfElements, props.maxNumberOfElements); i++) {
        skeleton.push(<Skeleton
            animation={false}
            height={40}
            width={`${randomIntFromInterval(props.minWidthPercentage, props.maxWidthPercentage)}%`}
            key={i}
            />
        )
    }
    return skeleton
}

export default function RandomisedSkeleton(props: SkeletonProps) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <CreateSkeleton {...props} />
        </div>
    );
}