import React, {FunctionComponent} from "react"
import MainLayout from "../components/dashboardcontent/MainLayout"
import {Typography} from "@material-ui/core"
import TestPage from "./TestPage"

const DefaultDashboard: FunctionComponent = () => {

    return (
        <MainLayout
            header={<Typography>header content...</Typography>}
            rightPane={<Typography>right content...</Typography>}
        >
            <TestPage/>
        </MainLayout>
    )
};

export default DefaultDashboard