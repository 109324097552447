import environment from "../../Environment";
import {createContext, useContext} from "react";
import {useSelectorWithDispatchIfNotPresent} from "../../hooks/selector";
import {User} from "../../model/User";
import {createResourceByIdService, ResourceByIdService} from "../../services/ResourceByIdService";
import {getResourceById} from "../../selectors/resource-by-id";
import {fetchResourceById} from "../../actions/resource-by-id";
import { getKonnectifyState } from './common';
import { RootState } from '../../state/store';

// Service
export const KONNECTIFY_USERS_RESOURCE = 'konnectify/users';
const userConnectionService = createResourceByIdService<Array<User>>(environment.konnectifyServiceUrl, companyId =>`/users/companyId/${companyId}`);
export const UserConnectionServiceContext = createContext<ResourceByIdService<Array<User>>>(userConnectionService);

//selector
const getUsers = (state: RootState) => getKonnectifyState(state).users;
const getUsersByCompany = (id: string) => (state: RootState) => getResourceById<Array<User>>(id)(getUsers(state));

//hook
export const useUsersForCompany = (companyId?: string) => {
    const service = useContext(UserConnectionServiceContext)

    return useSelectorWithDispatchIfNotPresent(
        companyId ? getUsersByCompany(companyId) : () => null,
        dispatch => companyId && dispatch(fetchResourceById(KONNECTIFY_USERS_RESOURCE, service, companyId))
    )
}
