import { FunctionComponent} from "react";
import { RouteConfig } from "../../router/RouteConfig";
import { List, ListSubheader, MenuItem, ListItemText, Paper, Divider, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { HomeRoutes, ConnectionRoutes, ServiceRoutes } from "../../router/DashboardRouter";

const NavBar: FunctionComponent = () => {

    const classes = useStyles();
    const history = useHistory()

    const activeRoute = (routeName: any) => {
        return history.location.pathname.indexOf(routeName) > -1 ? true : false;
    }
    
    const renderSection = (routes: Array<RouteConfig>, sectionName: string) => {
        
        return (
            <List
                subheader={<ListSubheader data-testid="list-sub-header" component="div">{sectionName}</ListSubheader>}
            >
                {routes.map((prop, key) => {
                    return (
                        <Link to={prop.path} style={{ textDecoration: 'none' }} key={key}>
                            <MenuItem dense selected={activeRoute(prop.path)}>
                                {/* <ListItemIcon>
                                    <prop.icon className={classes.icon}/>
                                </ListItemIcon> */}
                                <ListItemText primary={prop.sidebarName} classes={{ primary: classes.listItemText }} />
                            </MenuItem>
                        </Link>
                    );
                })}
            </List>
        )
    }

    return (
            <Paper>
                <Divider />
                    {renderSection(HomeRoutes, "")}
                <Divider />
                    {renderSection(ConnectionRoutes, "Connections")}
                <Divider />
                    {renderSection(ServiceRoutes, "Services")}
                <Divider />
            </Paper>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    listItemText: {
        color: 'black'
    }
    
}));

export default NavBar