import React, {FunctionComponent, useEffect} from 'react';
import useAuth from '../useAuth';
import { CircularProgress } from '@material-ui/core';

const LoginCallback: FunctionComponent = () => {

    const auth = useAuth();
     
    useEffect(() => {
        
        console.log('LoginCallback effect called');
        
        let search = window.location.search;
        console.log('processing window location ', window.location)
        let params = new URLSearchParams(search);
        let state = params.get('state');
        let token = params.get('token');
        console.log('extract state {}, token {}', state, token);

        if (state && token) {
            auth.authenticateFromCallback(state, token)
        }
       
        window.close()
    },[auth])

    return (
        <CircularProgress/>

    )
}

export default LoginCallback