import React from 'react';
import moment from 'moment';
import Panel from '../panel/Panel';
import Address from "./address/Address";
import { Company, Subsidiary } from '../../model/Company';
import { useCompany } from '../../hooks/company';
import KeyValues from '../panel/KeyValues';
import { makeStyles, createStyles } from '@material-ui/core';

const CompanyInfoContainer = () => {
    const company = useCompany('ZC00000001');
    return (
        <>
            {company && <CompanyInfo {...company}/>}
        </>
    );
};

const CompanyInfo = ({ name, localName, contact: { email }, address, subsidiaries, license }: Company) => {
    const classes = useStyles();
    return (
        <Panel title="Company Information">
            <KeyValues entries={[
                {
                    key: 'company-name-en',
                    label: 'Company name (in English)',
                    value: name
                },
                {
                    key: 'company-name',
                    label: 'Company name (in local language)',
                    value: localName
                },
                {
                    key: 'contact-email',
                    label: 'Main contact',
                    value: email
                },
                {
                    key: 'address',
                    label: 'Address',
                    value: address && <Address {...address} />
                },
                {
                    key: 'subsidiaries',
                    label: 'Subsidiaries',
                    value: subsidiaries.length !== 0 && (
                        <ul className={classes.list}>
                            {subsidiaries.map(({ name }: Subsidiary) => (
                                <li key={name} data-testid="subsidiary-name">{name}</li>
                            ))}
                        </ul>
                    )
                },
                {
                    key: 'license-number',
                    label: 'Business license number/identification number',
                    value: license && license.number
                },
                {
                    key: 'license-expiry',
                    label: 'Business license number/identification number',
                    value: license && license.expiry && moment(license.expiry).format('DD/MM/YYYY')
                }
            ]}/>
        </Panel>
    )
};

const useStyles = makeStyles(() => createStyles({
    list: {
        padding: 0,
        margin: 0,
        listStyle: 'none'
    }
}));

export default CompanyInfoContainer;
