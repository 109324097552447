import React from 'react';
import Panel from '../../panel/Panel';
import KeyValues from '../../panel/KeyValues';
import Address from "../address/Address";
import { BillingInfo as BillingInfoProps } from '../../../model/BillingInfo';
import { useBillingInfo } from '../../../hooks/profile';

const BillingInfoContainer = () => {
    const billingInfo = useBillingInfo();
    return (
        <>
            {billingInfo && <BillingInfo {...billingInfo}/>}
        </>
    );
};

const BillingInfo = (billingInfo: BillingInfoProps) => (
    <Panel title="Billing Info">
        <KeyValues entries={[
            {
                key: 'address',
                label: 'Address',
                value: <Address {...billingInfo.address}/>
            },
            {
                key: 'vat-number',
                label: 'VAT number',
                value: billingInfo.vat.number
            }
        ]}/>
    </Panel>
);

export default BillingInfoContainer;
