import React from 'react';
import { Typography, makeStyles, createStyles, Theme } from '@material-ui/core';

export type KeyValueEntry = {
    key: any,
    label?: any,
    value: any
};

const KeyValues = ({ entries, gutter = true }: { entries: KeyValueEntry[], gutter?: boolean }) => {
    const classes = useStyles();
    return (
        <dl className={gutter ? classes.listWithGutter : classes.listWithoutGutter}>
            {entries.filter(entry => entry.value).map(entry => (
                <div key={entry.key} className={classes.entry}>
                    <Typography variant="caption" component="dt" classes={{ root: classes.label }}>{entry.label || entry.key}</Typography>
                    <Typography component="dd" data-testid={entry.key}>{entry.value}</Typography>
                </div>
            ))}
        </dl>
    );
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    label: {
        '&::after': {
            content: '":"'
        }
    },
    entry: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    listWithGutter: {},
    listWithoutGutter: {
        marginTop: -theme.spacing(1),
        marginButton: -theme.spacing(1)
    }
}));

export default KeyValues;
