import React, {FunctionComponent, useState} from 'react';
import {Button, createStyles, Grid, Menu, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Theme} from "@material-ui/core/styles/createMuiTheme";
import useAuth from '../useAuth';
import { AuthProps } from './AuthMenu';

const SignIn: FunctionComponent<AuthProps> = (props: AuthProps) => {
    const classes = useStyles();
    const isMenuOpen = Boolean(props.anchorEl);
    const [isAuthed, setAuthed] = useState(false);
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const auth = useAuth();

    const handleSignIn = () => {
        setAuthed(!auth.signIn(userName, password));
    };

    const clearInputError = () => {
        setAuthed(false)
    };

    const handleChangeUserName = (event: any) => {
        setUserName(event.target.value);
        clearInputError()
    };

    const handleChangePassword = (event: any) => {
        setPassword(event.target.value);
        clearInputError();
    };

    const stopPropagationForTab = (event: any) => {
        if (event.key === "Tab") {
            event.stopPropagation();
        }
    };

    const submitOnEnter = (event: any) => {
        stopPropagationForTab(event);
        if (event.key === "Enter") {
            handleSignIn()
        }
    };

    return(
        <Grid container>
            <Menu
                id="simple-menu"
                anchorEl={props.anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                open={isMenuOpen}
                onClose={props.handleClose}
                className={classes.menu}
                PaperProps={{
                    style: {
                        width: '60ch',
                        padding: '1ch'
                    }
                }}
            >
                <Grid container className={classes.noFocus}>
                    <Grid item xs={12}>
                        <h2 className={classes.heading}>Enter your details to Sign in</h2>
                        <form noValidate autoComplete="off" >
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={5} md={5} lg={5}>
                                    <TextField
                                        id="sign-in-username"
                                        data-testid="sign-in-username"
                                        label="Username"
                                        error={isAuthed}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        onChange={handleChangeUserName}
                                        onKeyDown={stopPropagationForTab}
                                        tabIndex={2}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={5} md={5} lg={5}>
                                    <TextField
                                        id="sign-in-password"
                                        data-testid="sign-in-password"
                                        label="Password"
                                        error={isAuthed}
                                        type="password"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        autoComplete="current-password"
                                        onChange={handleChangePassword}
                                        onKeyDown={submitOnEnter}
                                        tabIndex={1}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} lg={2}>
                                    <Button
                                        id="sign-in-button"
                                        data-testid="sign-in-button"
                                        className={classes.button}
                                        variant="contained"
                                        size="small"
                                        fullWidth
                                        onClick={handleSignIn}
                                        tabIndex={0}
                                    >
                                        Sign In
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        <p className={classes.link}><a href='/#'> Forgot your details?</a></p>
                    </Grid>
                </Grid>
            </Menu>
        </Grid>
    )
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menu: {
            width: '100%'
        },
        noFocus: {
            outline: 'none',
        },
        menuMargin: {
            margin: '5px'
        },
        menuText: {
            color: 'black'
        },
        button: {
            textSize: '11px',
            height: '5.5ch'
        },
        heading: {
            marginTop: '0px',
        },
        link: {
            marginBottom: '0px',
        }
    }),
);

export default SignIn
