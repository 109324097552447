import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Button, Grid, TextField} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        button: {
            width: '100%',
            height: '5.5ch'
        }
    }),
);

export default function BasicTextFields() {
    const classes = useStyles();

    return (
        <form className={classes.root} noValidate autoComplete="off" >
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                    <TextField label="FirstName" variant="outlined" size="small" fullWidth/>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                    <TextField label="LastName" variant="outlined" size="small" fullWidth/>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField label="Email" variant="outlined" size="small" fullWidth/>
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={2}>
                    <Button data-testid="email-subscribe-button" className={classes.button} variant="contained" size="small" fullWidth>Subscribe</Button>
                </Grid>
            </Grid>
        </form>
    );
}