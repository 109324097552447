const CHANGE_USER = 'user/change';
export const changeUser = (user: any) => ({
    type: CHANGE_USER,
    user
});

const reducer = (state: any = null, action: any) => {
    switch (action.type) {
        case CHANGE_USER:
            return action.user;
        default:
            return state;
    }
};

export const userState = (reducer: (state: any, action: any) => any) => (state: any, action: any) => {
    switch (action.type) {
        case CHANGE_USER:
            return reducer(undefined, action);
        default:
            return reducer(state, action);
    }
};

export default reducer;
