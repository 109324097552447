import {Company} from "../../model/Company";
import environment from "../../Environment";
import {createContext} from "react";
import {createResourceService, ResourceService} from "../../services/ResourceService";
import { getKonnectifyState, useAutoRefreshResource } from './common';
import { RootState } from '../../state/store';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

// Service
export const KONNECTIFY_COMPANIES_RESOURCE = 'konnectify/companies';
const companyConnectionService = createResourceService<Array<Company>>(environment.konnectifyServiceUrl, `/companies/`);
export const CompanyConnectionServiceContext = createContext<ResourceService<Array<Company>>>(companyConnectionService);

//selector
const getCompaniesState = (state: RootState) => getKonnectifyState(state).companies;

//actions
export const FETCH_RESOURCE_SUCCESS = 'resource/fetch/success';
const fetchSuccess = <T> (resourceName: string, resource: T) => ({
    type: FETCH_RESOURCE_SUCCESS,
    resourceName,
    resource
});

export const FETCH_RESOURCE = 'resource/fetch';
const fetch = (resourceName: string) => ({
    type: FETCH_RESOURCE,
    resourceName
});

export const fetchResourceList = <T> (resourceName: string, service: ResourceService<Array<T>>) => (dispatch: ThunkDispatch<RootState, undefined, Action>) => {
    dispatch(fetch(resourceName));
    return service.get()
        .then(result => dispatch(fetchSuccess(resourceName, result)));
};

//hook
export const useCompanies = () => {
    return useAutoRefreshResource(KONNECTIFY_COMPANIES_RESOURCE, getCompaniesState, CompanyConnectionServiceContext);
}
