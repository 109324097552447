import { FETCH_RESOURCE_SUCCESS, FETCH_RESOURCE } from '../actions/resource';
import { combineReducers } from 'redux';

interface Action {
    type: string
};

interface ResourceAction<T> extends Action {
    resource: any,
    resourceName: string
};

export enum ResourceStatus {
    Init = "INIT",
    Loading = "LOADING",
    Ready = "READY",
    Stale = "STALE"
};

export type ResourceState<T> = {
    resource: T,
    status: ResourceStatus
};

const initialResource = null;
const resourceReducer = <T> (resourceName: string) => (state = initialResource, action: Action) => {
    if ((action as ResourceAction<T>).resourceName !== resourceName) {
        return state;
    }
    switch (action.type) {
        case FETCH_RESOURCE_SUCCESS:
            return (action as ResourceAction<T>).resource;
        default:
            return state;
    }
};

const initialResourceState = ResourceStatus.Init;
const statusReducer = <T> (resourceName: string, isInvalidated: (action: Action) => boolean = () => false) => (state = initialResourceState, action: Action) => {
    switch (action.type) {
        case FETCH_RESOURCE:
            return (action as ResourceAction<T>).resourceName === resourceName ? ResourceStatus.Loading : state;
        case FETCH_RESOURCE_SUCCESS:
            return (action as ResourceAction<T>).resourceName === resourceName ? ResourceStatus.Ready : state;
        default:
            return isInvalidated(action) ? ResourceStatus.Stale : state;
    }
};

type ResourceOpts = {
    invalidatedWhen?: (action: Action) => boolean
}

export default <T> (resourceName: string, resourceOpts: ResourceOpts = {}) => combineReducers({
    resource: resourceReducer<T>(resourceName),
    status: statusReducer(resourceName, resourceOpts.invalidatedWhen)
});
