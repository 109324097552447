import { getUserState } from '../common';
import { RootState } from '../../../state/store';

export const getConnectionCounts = (state: RootState) => getUserState(state).counts;

export const getConnections = (state: RootState) => getUserState(state).connections;

export const getConnectionRequests = (state: RootState) => getUserState(state).connectionRequests;

export const getConnectionSuggestions = (state: RootState) => getUserState(state).eligibleConnections;
