import React from 'react';
import {Grid} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import Panel from "../../panel/Panel";
import KeyValues from '../../panel/KeyValues';

export type UserInfoProps = {
    firstName: string,
    lastName: string,
    companyName: string,
    companyRole: string
}

const UserInfo = (userInfo: UserInfoProps) => {
    return(
        <Panel title="Your Profile">
            <Grid container direction="row" item xs={12} spacing={4}>
                <Grid item>
                    <Skeleton data-testid="user-profile-image" variant="circle" height={100} width={100} animation={false}/>
                </Grid>
                <Grid item xs>
                    <KeyValues entries={[
                        {
                            key: 'user-full-name',
                            label: 'Name',
                            value: `${userInfo.firstName} ${userInfo.lastName}`
                        },
                        {
                            key: 'user-company-role',
                            label: 'Role',
                            value: userInfo.companyRole
                        }
                    ]} gutter={false}/>
                </Grid>
                <Grid item>
                    <Grid item>
                        <KeyValues entries={[
                            {
                                key: 'user-company-name',
                                label: 'Company name',
                                value: userInfo.companyName
                            }
                        ]} gutter={false}/>
                    </Grid>
                </Grid>
            </Grid>
        </Panel>
    )
};

export const HardCodedUserInfo = () => {
    return <UserInfo {...require('./userInfo.json')}/>
};

export default UserInfo
