import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Grid} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import FooterContactForm from "./FooterContactForm";
import RandomisedSkeleton from "../RandomisedSkeleton";
import logo from '../../assets/images/sedex_logo_gray.png';

export default function Footer() {
    const classes = useStyles();
    const columnNumbers = [1, 2, 3, 4]
    const createSkeletonColumns = columnNumbers.map((i: number) =>
        <Grid item xs={12} sm={6} md={3} lg={3} key={i}>
            <RandomisedSkeleton minNumberOfElements={1}
                                maxNumberOfElements={5}
                                minWidthPercentage={30}
                                maxWidthPercentage={100}
            />
        </Grid>
    );

    return (
        <footer className={classes.footer}>
            <Container maxWidth="lg">
            <Grid container justify="flex-start" spacing={1}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <img src={logo} width='108px' height='35px' alt="Sedex Logo"/>
                        </Grid>
                        <Grid item xs={12}>
                            <Skeleton className={classes.skeleton} variant="text" animation={false} width='50%'/>
                            <Skeleton className={classes.skeleton} variant="text" animation={false}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={8} md={8} lg={8}>
                    <Grid item xs={12}>
                        <FooterContactForm />
                        <Skeleton className={classes.skeleton} variant="text" animation={false} width='25%'/>
                        <Skeleton className={classes.skeleton} variant="text" animation={false} width='50%'/>
                    </Grid>
                </Grid>
            </Grid>
            </Container>
            <Container maxWidth="lg">
                <Grid container>
                    {createSkeletonColumns}
                </Grid>
            </Container>
        </footer>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex"
    },
    grid: {
        flex: 1,
        display: "flex",
        height: '100%',
        alignItems: "left",
        justifyContent: "left"
    },
    footer: {
        padding: theme.spacing(3, 2),
        marginTop: 'auto',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[800],
    },
    skeleton: {
        width: "100%",
        padding: "1ch"
    }

}));